<template>
  <div class="ExceptionHandling">
    <div class="header-box">
      <span class="golink" @click="$router.go(-1)"
        ><i class="el-icon-arrow-left"></i>返回上一级</span
      >
      <refreshTime></refreshTime>
    </div>
    <div v-loading="listLoading" class="operation-box">
      <div class="question-header">
        <div class="question-header-left">
          <span> 报警题目：{{ studentInfo.questionNum }}题 </span>
          <span>
            异常类型：<span class="tips">
              {{ studentInfo.type | setName(exceptionTypes) }}
            </span>
          </span>
          <span> 报警人：{{ studentInfo.teacherName }} </span>
        </div>
        <div class="question-header-right">
          <span class="tips" @click="adjust(studentInfo)">
            <img src="@/assets/uExam/icon_task_on.png" alt="" />
            调整小题区域
          </span>
          <span
            >查看答题卡：
            <el-button type="text" @click="showImg(0)">正面</el-button>
            <el-button type="text" @click="showImg(1)">反面</el-button>
          </span>
        </div>
      </div>
      <div class="question-img-box edit-scroll-style">
        <img :src="studentInfo.questionUrl" alt="" />
      </div>
      <div class="question-bottom">
        <div class="question-bottom-box">
          <div class="bottom-item">
            处理报警
            <el-select
              v-model="studentInfo.state"
              placeholder="请选择"
              style="width: 180px"
            >
              <template v-for="item in stateTypeList">
                <el-option
                  v-if="!item.type || (item.type && userInfo.roleId != 5)"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </template>
            </el-select>
          </div>
          <template v-if="studentInfo.state == 4">
            <template v-if="studentInfo.ifPoint">
              最终得分(分项给分)：
              <template v-for="(item, index) in pointScoreList">
                <div :key="index" class="score-item">
                  分项{{ (index + 1) | capitalNumber }}：
                  <el-input
                    v-model="item.score"
                    placeholder=""
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                    @input="setScore()"
                    @blur="item.score = $event.target.value"
                  ></el-input>
                  (分项小分：{{ item.pointQuestionScore }})
                </div>
              </template>
              <div class="score-item">
                总分：
                <el-input v-model="studentInfo.score" disabled placeholder="">
                </el-input>
              </div>
            </template>
            <template v-if="studentInfo.isBind">
              绑定批阅：
              <template v-for="(item, index) in bindList">
                <div :key="index" class="score-item">
                  {{ item.questionNum }}：
                  <el-input
                    v-model="item.studentScore"
                    placeholder=""
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                    @input="setScore1()"
                    @blur="item.studentScore = $event.target.value"
                  ></el-input>
                  (小题总分：{{ item.originalScore }})
                </div>
              </template>
              <div class="score-item">
                总分：
                <el-input v-model="studentInfo.score" disabled placeholder="">
                </el-input>
              </div>
            </template>
            <template v-if="!studentInfo.ifPoint && !studentInfo.isBind">
              <div class="score-item">
                最终得分：
                <el-input
                  v-model="studentInfo.score"
                  placeholder=""
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  @blur="studentInfo.score = $event.target.value"
                ></el-input>
              </div>
              (小题总分：{{ studentInfo.sheetDetailScore }})</template
            ></template
          >
        </div>
        <div>
          <el-button type="primary" :loading="btnLoading" @click="submit()"
            >保存处理</el-button
          >
          <el-button type="primary" @click="next()">跳过暂不处理</el-button>
        </div>
      </div>
    </div>
    <div class="err-tips">
      <img src="@/assets/uExam/icon_waring.png" alt="" />
      注意：处理时删除试卷操作如果删除的是合科考试的答题卡，会同时删除对应的正面或背面答题卡。
    </div>
  </div>
</template>

<script>
const stateTypeList = [
  {
    value: 1,
    label: "保留记录，并打回",
  },
  {
    value: 2,
    label: "取消记录，并打回",
  },
  {
    value: 3,
    label: "删除试卷",
    type: 1,
  },
  {
    value: 4,
    label: "直接打分",
  },
];
import { exceptionTypes } from "@/core/util/constdata.js";
import refreshTime from "../components/refreshTime.vue";
import { getStore, setStore } from "@/core/util/store";
import {
  markabnormolQuestion,
  markabnormolHandel,
  questionUnlock,
} from "@/core/api/exam/paperMonitor";
export default {
  name: "ExceptionHandling",
  components: { refreshTime },
  data() {
    return {
      indexStateType: "",
      stateTypeList: stateTypeList,
      exceptionTypes: exceptionTypes,
      btnLoading: false,
      listLoading: false,
      studentInfo: {},
      pointScoreList: [],
    };
  },
  created() {
    this.getNewView();
    this.userInfo = getStore({
      name: "userInfo",
    });
  },
  destroyed() {
    let data = {
      id: this.studentInfo.id,
      userId: this.userInfo.userId,
    };
    questionUnlock(data);
  },
  methods: {
    showImg(index) {
      let imgs = this.studentInfo.sheetUrl.split("，");
      window.open(imgs[index]);
    },
    adjust(item) {
      let Base64 = require("js-base64").Base64;
      let data = {
        studentId: item.studentId,
        examId: this.$route.query.examId,
        questionNum: item.questionNum,
      };
      let code = Base64.encode(item.studentName);
      data.code = code;
      let query = Object.assign({}, this.$route.query);
      delete query.studentId;
      delete query.questionNum;
      delete query.examId;
      delete query.code;
      Object.assign(data, query);

      this.$router.push({
        name: "unionMarkStudent",
        query: data,
      });
    },
    setScore() {
      let score = 0;
      this.pointScoreList.map((item) => {
        score = score + Number(item.score);
      });
      this.studentInfo.score = score;
    },
    setScore1() {
      let score = 0;
      this.bindList.map((item) => {
        score = score + Number(item.studentScore);
      });
      this.studentInfo.score = score;
    },
    submit() {
      let Max = ["一", "二", "三", "四", "五", "六", "七", "八"];
      let numArr = [];
      let maxArr = [];
      if (!this.studentInfo.state) {
        this.$message({
          showClose: true,
          message: "请选择处理类型！",
          type: "warning",
        });
        return;
      }

      if (this.studentInfo.ifPoint && this.studentInfo.state == 4) {
        this.studentInfo.pointScoreList = this.pointScoreList;
        this.pointScoreList.map((item, index) => {
          if (item.score === "") {
            numArr.push(Max[index]);
          } else {
            if (Number(item.score) > Number(item.pointQuestionScore)) {
              maxArr.push(Max[index]);
            }
          }
        });
      }
      let bindnumArr = [];
      let bindmaxArr = [];
      if (this.studentInfo.isBind && this.studentInfo.state == 4) {
        this.studentInfo.bindList = this.bindList;
        this.bindList.map((item) => {
          if (item.studentScore === "") {
            bindnumArr.push(item.questionNum);
          } else {
            if (Number(item.studentScore) > Number(item.originalScore)) {
              bindmaxArr.push(item.questionNum);
            }
          }
        });
      }
      if (numArr.length > 0) {
        this.$message({
          showClose: true,
          message: "请填写完所有分项",
          type: "warning",
        });
        return;
      }
      if (maxArr.length > 0) {
        this.$message({
          showClose: true,
          message: "分项得分不能大于分项分数",
          type: "warning",
        });
        return;
      }
      if (bindnumArr.length > 0) {
        this.$message({
          showClose: true,
          message: "请填写完所有绑定批阅分数",
          type: "warning",
        });
        return;
      }
      if (bindmaxArr.length > 0) {
        this.$message({
          showClose: true,
          message: "小题得分不能大于小题分数",
          type: "warning",
        });
        return;
      }
      if (
        this.studentInfo.score > this.studentInfo.sheetDetailScore &&
        this.studentInfo.state == 4
      ) {
        this.$message({
          showClose: true,
          message: "小题得分不能大于总分",
          type: "warning",
        });
        return;
      }
      if (isNaN(Number(this.studentInfo.score))) {
        this.$message({
          message: "分数输入不合法，请重新输入",
          type: "warning",
        });
        return;
      }

      this.upQuestion();
    },
    upQuestion() {
      this.btnLoading = true;
      delete this.studentInfo.examPaperId;
      markabnormolHandel(this.studentInfo)
        .then(() => {
          this.$message({
            showClose: true,
            message: "保存成功，已为您跳转至下一题！",
            type: "success",
          });
          this.getList();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    getNewItem() {
      this.getNewView();
    },
    getNewView() {
      let setErrorId = getStore({
        name: "setErrorId",
        type: 1,
      });
      this.getList(setErrorId);
    },
    async next() {
      let data = {
        id: this.studentInfo.id,
        userId: this.userInfo.userId,
      };

      await this.getList();
      questionUnlock(data);
    },
    showOver() {
      this.$confirm(
        "当前异常已处理完毕，请点击‘返回异常处理页面’返回列表页",
        "提示",
        {
          confirmButtonText: "返回异常处理页面",
          cancelButtonText: "",
          showCancelButton: true,
          type: "warning",
        }
      )
        .then(() => {
          this.$router.push({
            name: "unionMarkingTheAlarm",
            query: this.$route.query,
          });
        })
        .catch(() => {});
    },
    showLock() {
      this.$confirm(
        "当前异常已有人处理，获取下一个异常请点击‘ 继续 ’",
        "提示",
        {
          confirmButtonText: "继续",
          cancelButtonText: "返回异常处理页面",
          type: "warning",
        }
      )
        .then(() => {
          this.getList();
        })
        .catch(() => {
          this.$router.push({
            name: "unionMarkingTheAlarm",
            query: this.$route.query,
          });
        });
    },
    async getList(id) {
      let data = {
        examPaperId: this.$route.query.examPaperId,
        id: id,
      };
      this.listLoading = true;
      await markabnormolQuestion(data)
        .then((res) => {
          this.listLoading = false;
          this.btnLoading = false;
          if (!res.data.data) {
            this.$message({
              showClose: true,
              message: "数据异常，请返回后重新进入后再试！",
              type: "error",
            });
            return;
          }
          // this.ifLock = res.data.data.ifLock;
          if (res.data.data.ifLock) {
            this.showLock();
            return;
          }
          if (res.data.data.ifOver) {
            this.showOver();
            return;
          }

          setStore({
            name: "setErrorId",
            content: res.data.data.id,
            type: 1,
          });
          if (res.data.data.state == 0) {
            res.data.data.state = "";
          }
          if (res.data.data.bindList) {
            res.data.data.isBind = true;
            this.bindList = res.data.data.bindList.map((item) => {
              if (!item.studentScore) {
                item.studentScore = "";
              }
              return item;
            });
          }
          this.studentInfo = res.data.data;
          if (!this.studentInfo.state) {
            this.studentInfo.state = 1;
          }
          this.pointScoreList = res.data.data.pointScoreList.map((item) => {
            if (!item.score) {
              item.score = "";
            }
            return item;
          });
        })
        .catch(() => {
          this.listLoading = false;
          this.btnLoading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.ExceptionHandling {
  padding: 18px;
  .err-tips {
    background: #fff3f3;
    border-radius: 4px;
    padding: 10px 18px;
    margin-top: 18px;
  }
  .question-bottom {
    background: #f9fafc;
    border-top: 1px solid #dee0e7;
    padding: 18px;
    .question-bottom-box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 18px;
      .bottom-item {
        margin-right: 18px;
      }
      .score-item {
        margin-right: 18px;
        .el-input {
          width: 100px;
        }
      }
    }
  }
  .question-img-box {
    padding: 18px;
    max-height: 300px;
    min-height: 200px;
    img {
      max-width: 100%;
    }
  }
  .header-box {
    .golink {
      color: #2474ed;
      margin-right: 18px;
      cursor: pointer;
    }
    // margin-bottom: 18px;
  }
  .operation-box {
    border: 1px solid #dee0e7;
    .question-header {
      background: #f9fafc;
      border-bottom: 1px solid #dee0e7;
      display: flex;
      justify-content: space-between;
      padding: 12px 18px;
      .question-header-right {
        span {
          margin-left: 24px;
        }

        .tips {
          color: #2474ed;
          cursor: pointer;
        }
      }
      .question-header-left {
        .tips {
          color: #f56c6b;
        }
        > span {
          margin-right: 24px;
        }
      }
    }
  }
}
</style>
